import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import ModalHeader from '../ModalHeader'
import styled from '@emotion/styled'

interface Props {
  handleClose: () => void
  retakeQuiz: () => void
  name: string
  learnMoreAvailable: boolean
  percentCorrect: number
  onLearnMoreClicked?: () => void
}

const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px !important;
`

const FinalScreen = (props: Props) => {
  const { handleClose, percentCorrect } = props

  const isPassed = percentCorrect === 100

  return (
    <Stack spacing={2}>
      <ModalHeader handleClose={handleClose}>
        <Typography fontWeight={400} variant="h5">
          Results panel
        </Typography>
      </ModalHeader>

      <div>
        <Paragraph>
          You have correctly answered {percentCorrect}% of questions. This is{' '}
          {percentCorrect < 75 ? 'not' : ''} a passing grade.
        </Paragraph>
        <Paragraph>
          Your score for this lesson is recorded in your Results list in Progress.
        </Paragraph>
        {!isPassed && (
          <Paragraph>
            You can learn more about this topic and then retake this quiz to improve your score, or
            retake quiz now, if you wish.
          </Paragraph>
        )}
      </div>
      <Footer>
        {props.learnMoreAvailable && (
          <Button variant="contained" color="secondary" onClick={props.onLearnMoreClicked}>
            Learn More
          </Button>
        )}
        {!isPassed && (
          <Button
            variant="contained"
            color="secondary"
            onClick={props.retakeQuiz}
            sx={{ marginLeft: '16px' }}
          >
            Retake Quiz
          </Button>
        )}
      </Footer>
    </Stack>
  )
}

export default FinalScreen
